
import { defineComponent } from 'vue'
import Sidebar from '@/shared/components/Sidebar.vue'
import { NavigationItem } from '@/shared/interfaces/navigation.model'
import { ReportActions } from '@/store/modules/reports/actions'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { connectToFirebase } from '@/firebase'
import jwtDecode from 'jwt-decode'
import { useToast } from 'vue-toastification'
import FirebaseMessage from '@/shared/components/FirebaseMessage.vue'
import router from '@/router'
// import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { NotificationInterface } from '@/shared/interfaces/notification.interface'
import { NotificationActions } from '@/store/modules/notifications/actions'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'

export default defineComponent({
  name: 'Account',
  components: {
    // BaseIcon,
    Sidebar
  },
  data: function () {
    return {
      navItems: [
        // {
        //   iconName: 'dashboard',
        //   routeTo: '/',
        //   routeText: 'nav.dashboard'
        // },
        {
          iconName: 'users',
          routeText: 'nav.drivers',
          routeTo: '/drivers'
        }, {
          iconName: '',
          routeTo: '/shifts',
          routeText: 'nav.shiftManagement',
          subMenus: [
            {
              routeTo: '/shifts/list',
              routeText: 'nav.workShifts'
            },
            {
              routeTo: '/shifts/requests',
              routeText: 'nav.driversWorkShifts'
            }
          ]
        }, {
          iconName: 'users',
          routeTo: '/orders',
          routeText: 'nav.orders.main',
          subMenus: [
            {
              routeTo: '/orders/all',
              routeText: 'nav.orders.all'
            }, {
              routeTo: '/orders/prime',
              routeText: 'nav.orders.prime'
            }, {
              routeTo: '/orders/assigned',
              routeText: 'nav.orders.assigned'
            }, {
              routeTo: '/orders/not_assigned',
              routeText: 'nav.orders.notAssigned'
            }, {
              routeTo: '/orders/ready_for_pick_up',
              routeText: 'nav.orders.readyForPickUp'
            }, {
              routeTo: '/orders/preparing',
              routeText: 'nav.orders.preparing'
            }, {
              routeTo: '/orders/picked_up',
              routeText: 'nav.orders.picked_up'
            }, {
              routeTo: '/orders/on_the_way',
              routeText: 'nav.orders.on_the_way'
            }, {
              routeTo: '/orders/at_the_address',
              routeText: 'nav.orders.at_the_address'
            }, {
              routeTo: '/orders/delivered',
              routeText: 'nav.orders.delivered'
            }, {
              routeTo: '/orders/to_return',
              routeText: 'nav.orders.to_return'
            }, {
              routeTo: '/orders/to_return_assigned',
              routeText: 'nav.orders.to_return_assigned'
            }, {
              routeTo: '/orders/returned',
              routeText: 'nav.orders.returned'
            }, {
              routeTo: '/orders/canceled',
              routeText: 'nav.orders.canceled'
            }, {
              routeTo: '/orders/delivered-not-settled',
              routeText: 'nav.orders.delivered_not_settled'
            }
          ]
        }, {
          iconName: 'vehicles',
          routeTo: '/dark-stores',
          routeText: 'nav.darkStores'
        }, {
          iconName: 'cache',
          routeTo: '/cache-settlements',
          routeText: 'nav.cacheSettlements.main',
          subMenus: [
            {
              routeTo: '/cache-settlements/all',
              routeText: 'nav.cacheSettlements.all'
            }, {
              routeTo: '/cache-settlements/requested',
              routeText: 'nav.cacheSettlements.requested'
            }, {
              routeTo: '/cache-settlements/approved',
              routeText: 'nav.cacheSettlements.approved'
            }, {
              routeTo: '/cache-settlements/rejected',
              routeText: 'nav.cacheSettlements.rejected'
            }
          ]
        },
        {
          iconName: 'vehicles',
          routeTo: '/vehicles',
          routeText: 'nav.vehicles'
        }, {
          iconName: '',
          name: 'reports',
          routeTo: '/reports',
          subMenus: [],
          routeText: 'nav.reports.main'
        }, {
          iconName: '',
          routeTo: '/manual-changes',
          routeText: 'nav.manualChanges'
        }, {
          iconName: 'vehicles',
          routeTo: '/users',
          routeText: 'nav.users'
        },
        {
          iconName: '',
          routeTo: '/zones',
          routeText: 'nav.zones.main'
        },
        {
          iconName: '',
          routeTo: '/location-tracking-service/trip-playback',
          routeText: 'nav.lts.main'
        },
        {
          iconName: '',
          routeTo: '/settings',
          routeText: 'nav.settings'
        }
      ] as NavigationItem[],
      isNotificationsOpen: false
    }
  },
  methods: {
    markAsReadHandler (): void {
      this.$store.dispatch(`notifications/${[NotificationActions.MARK_AS_READ]}`).then(() => {
        alert('mark as read finished')
      })
    },
    // initNotificationsScroll (): void {
    //   const notificationsRef: any = this.$refs.notificationsRef
    //   const notificationsScrollRef: any = this.$refs.notificationsScrollRef
    //   notificationsRef.addEventListener('scroll', () => {
    //     const offsetHeight = notificationsRef.offsetHeight
    //     const scrollTop = notificationsRef.scrollTop
    //     const innerHeight = notificationsScrollRef.clientHeight
    //     if (this.pagination?.pageNumber && this.pagination?.totalPage) {
    //       if ((offsetHeight + scrollTop > innerHeight) && this.pagination.totalPage > this.pagination.pageNumber) {
    //         this.$store.dispatch(`notifications/${[NotificationActions.FETCH_NOTIFICATIONS]}`,
    //           {
    //             pageNumber: this.pagination.pageNumber + 1,
    //             pageSize: this.pagination.pageSize
    //           }
    //         )
    //       }
    //     }
    //   })
    // },
    load (): void {
      console.log('11111')
    },
    clickOutsideNotification (): void {
      this.isNotificationsOpen = false
    },
    logout: function (): void {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/auth')
      })
    },
    getReportTypes (): void {
      this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORT_TYPES]}`)
    }
    // openNotifications (): void {
    //   this.isNotificationsOpen = !this.isNotificationsOpen
    // }
  },
  mounted () {
    // this.initNotificationsScroll()
    this.getReportTypes()
    const messaging = getMessaging()
    onMessage(messaging, (payload: any) => {
      let event
      if (payload.notification.title.includes('is delayed')) {
        event = () => router.push({ path: '/reports', query: { reportName: 'view_orders_timing', orderId: payload.notification.title.split(' ')[1] } })
      }
      if (payload.notification.title.includes('101: New order, with')) {
        if (this.$route.path === '/orders/all') {
          this.$store.dispatch('newOrderCreated', true)
        } else {
          router.push({ path: '/orders/all' })
        }
        return
      }
      const toast = useToast()
      const content = {
        component: FirebaseMessage,
        props: {
          title: payload.notification.title,
          content: payload.notification.body
        },
        listeners: {
          click: event
        }
      }
      toast.info(content, {
        timeout: 20000
      })
    })
  },
  created () {
    connectToFirebase().then((token: any) => {
      const decoded: any = jwtDecode(this.$cookie.getCookie('tasleem_token'))
      this.$store.dispatch('sendFirebaseToken', { userId: decoded.id, token: token })
    })
  },
  computed: {
    // reportTypes (): ReportTypesInterface[] {
    //   return this.$store.getters['reports/getReportTypes']
    // }
    notifications (): NotificationInterface[] {
      return this.$store.getters['notifications/getNotifications']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['notifications/getPagination']
    }
  },
  watch: {
    // routeText: `nav.reports.${report.name}`
    '$store.state.reports.reportTypes': {
      deep: true,
      immediate: true,
      handler (v) {
        if (v.length) {
          this.navItems = this.navItems.map(el => {
            if (el.name && el.name === 'reports') {
              el.subMenus = []
              v.forEach((report: any) => {
                if (el.subMenus) {
                  el.subMenus.push({
                    routeTo: `/reports/${report.name}`,
                    routeText: report.name.split('_').join(' ')
                  })
                }
              })
              el.subMenus.push({
                routeTo: '/reports/Darkstore_delivery_metrics',
                routeText: 'Darkstore Delivery Metrics'
              })
              el.subMenus.push({
                routeTo: '/reports/Zone_delivery_metrics',
                routeText: 'Zone Delivery Metrics'
              })
              el.subMenus.push({
                routeTo: '/reports/Driver_performance',
                routeText: 'Driver Performance'
              })
            }
            return el
          })
        }
      }
    }
  }
})
